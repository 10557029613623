import React, { forwardRef, FunctionComponent } from 'react'
import classNames from 'classnames'

import { Button } from '@goorm-dev/components'
import ChevronLeftIcon from '@goorm-dev/components/build/ChevronLeftIcon'
import ChevronRightIcon from '@goorm-dev/components/build/ChevronRightIcon'
import SectionWrapper from '_components/Common/SectionWrapper/SectionWrapper'
import Responsive from '_components/Common/Responsive/Responsive'
import { formattedDate } from '_utils/utils'

import * as styles from './NoticeDetail.module.scss'

type NoticeDetailProps = {
  title: string
  date: string
  content: string
  previous: number
  next: number
}

const NoticeDetail: FunctionComponent<NoticeDetailProps> = function ({
  title,
  date,
  content,
  previous,
  next,
}, ref) {
  return (
    <SectionWrapper
      ref={ref}
      className={styles.NoticeDetail}
    >
      <h1 className={styles.NoticeDetail__header}>공지사항</h1>
      <div className={styles.NoticeDetail__title}>
        <h3 className="text-gray-900">{title}</h3>
        <p className="text-gray-600 paragraph-lg">{formattedDate(date)}</p>
      </div>
      <div dangerouslySetInnerHTML={{ __html: content }} className={styles.NoticeDetail__content} />
      <footer className={styles.NoticeDetail__footer} >
          <div className={styles.NoticeDetail__footer__left}>
            <Button size="lg" color="basic" outline href={`/notice/${previous}`} disabled={!previous}><ChevronLeftIcon /></Button>
            <Button size="lg" color="basic" outline href={`/notice/${next}`} disabled={!next}><ChevronRightIcon /></Button>
          </div>
          <Button size="lg" color="basic" outline href="/notice">목록 보기</Button>
      </footer>
    </SectionWrapper>
  )
}

export default forwardRef(NoticeDetail)