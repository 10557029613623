import React, { FunctionComponent, forwardRef, ReactNode } from 'react'
import classNames from 'classnames'
import { Container } from '@goorm-dev/components'

import * as styles from './SectionWrapper.module.scss'

type SectionWrapperProps = {
  fluid?: string
  className?: string
  innerClassName?: string
  id?: string
  children: ReactNode
}

const SectionWrapper: FunctionComponent<SectionWrapperProps> = function (
  { id, fluid = 'lg', className = '', innerClassName = '', children },
  ref,
) {
  return (
    <section
      id={id}
      ref={ref}
      className={classNames(className, styles.wrapper)}
    >
      <Container fluid={fluid} className={innerClassName}>
        {children}
      </Container>
    </section>
  )
}

export default forwardRef(SectionWrapper)
