import React from "react"
import { graphql } from "gatsby"

import PageWrapper from '_components/Common/PageWrapper/PageWrapper'
import Header from '_components/Common/Header/Header'
import NoticeDetail from '_containers/Notice/NoticeDetail/NoticeDetail'

const NoticePostTemplate = ({
  pageContext: { title, date, content, previous, next }
}) => {
  return (
    <PageWrapper Header={<Header color='light' />}>
      <NoticeDetail title={title} date={date} content={content} previous={previous} next={next} />
    </PageWrapper>
  )
}

export default NoticePostTemplate
