export const getMediaQueryBreakpoint = customBreakpoints => {
  if (typeof window === 'undefined') {
    return null
  }
  const defaultMediaQueryBreakpointList = [
    {
      mediaQuery: window.matchMedia('(min-width: 0px)'),
      breakpoint: 'xs',
    },
    {
      mediaQuery: window.matchMedia('(min-width: 576px)'),
      breakpoint: 'sm',
    },
    {
      mediaQuery: window.matchMedia('(min-width: 768px)'),
      breakpoint: 'md',
    },
    {
      mediaQuery: window.matchMedia('(min-width: 992px)'),
      breakpoint: 'lg',
    },
    {
      mediaQuery: window.matchMedia('(min-width: 1200px)'),
      breakpoint: 'xl',
    },
    {
      mediaQuery: window.matchMedia('(min-width: 1440px)'),
      breakpoint: 'xxl',
    },
  ]
  const mediaQueryBreakpointList =
    Array.isArray(customBreakpoints) && customBreakpoints.length > 0
      ? defaultMediaQueryBreakpointList.filter(mqbp =>
          customBreakpoints.some(breakpoint => breakpoint === mqbp.breakpoint),
        )
      : defaultMediaQueryBreakpointList
  const getCurrentBreakpoint = () => {
    let currentBreakpoint = mediaQueryBreakpointList[0].breakpoint
    mediaQueryBreakpointList.some(mqbp => {
      if (mqbp.mediaQuery.matches) {
        currentBreakpoint = mqbp.breakpoint
        return false
      }
      return true
    })

    return currentBreakpoint
  }

  return { mediaQueryBreakpointList, getCurrentBreakpoint }
}

export const formattedDate = (date: string) => {
  const str = date.split('-')
  return str[0] + '.' + str[1] + '.' + str[2]
}
